.post-box {
    max-width: 97%;
    width: auto;
    height: auto;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 10px 10px;
    margin-bottom: 20px;
}

.post-title {
    font-weight: bolder;
    font-family: 'helvetica';
    text-align: left;
    font-size: large;
    margin: 0;
}

.post-content {
    font-family: 'helvetica';
    text-align: justify;
    margin: 0;
}

.post-date {
    font-family: 'helvetica';
    text-align: right;
    font-size: medium;
    font-weight: 400;
    margin: 0;
}

.title-div {
    width: 100%;
    height: auto;
}

.content-div {
    width: auto;
}