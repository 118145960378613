.career-card {
    background-color: #E5E4E2;
    height: auto;
    max-width: 923px;
    width: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: auto;
    display: flex;
    align-items: flex-start;
    padding: 30px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.career-text-card {
    background-color: #f0f0f0;
    height: auto;
    max-width: 350px;
    width: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 2% 4%;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

.section-title {
    margin: 0px;
    margin-bottom: 20px;
}

.link {
    color: black;

}

.career-locale {
    font-family: 'helvetica';
    text-align: left;
    font-size: medium;
    font-weight: 400;
    margin: 0;
}

.place-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

hr {
    flex-grow: 1;
    width: 100%;
  }