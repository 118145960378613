.TopBar {
    width: auto;
    width: -webkit-fill-available;
    height: 100px;
    background-color: #0047AB;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    justify-content: space-evenly;
    display: flex;
}

.Buttons {
    background-color: #0047AB;
    color: white;
    width: auto;
    font-weight: bolder;
    font-size: x-large;
    border-width: 0px;
    font-family: 'helvetica';
    align-items: center;
    display: flex;
    text-decoration: none;
}

.Buttons:hover {
    text-decoration: underline;
    font-size: x-large;
}

.SelectedButton{
    background-color: #0047AB;
    color: white;
    width: auto;
    font-weight: bolder;
    font-size: x-large;
    border-width: 0px;
    font-family: 'helvetica';
    align-items: center;
    display: flex;
}