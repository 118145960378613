.App {
  text-align: center;
  height: 100%;
  overflow-y:hidden;
  overflow-x:hidden;
}

.App-header {
  height: 100px;
  display: flex;
  flex-direction: column;
}

::selection {
  background: #0047AB;
  color: white;
}