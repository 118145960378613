.Footer{
    background-color:#f0f0f0 ;
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
}

.miniature {
    width: 18px;
    height: 18px;
    margin: 0px 10px;
}

.Source {
    color: black;
    margin: 0px 10px;
    font-family: 'helvetica';
}

.LastUpdt {
    font-size: small;
    margin: 0px 10px;
    font-family: 'helvetica';
}