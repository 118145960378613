.project-box {
    background-color: #f0f0f0;
    max-width: 350px;
    height: auto;
    width: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 3% 4%;
    display: block;
}

.furtherinfo {
    color: black;
}