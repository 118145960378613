.Gray-card{
    background-color: #E5E4E2;
    height: 80%;
    max-width: 923px;
    width: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: auto;
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: auto;
    gap: 30px;
}

.Text-card {
    background-color: #f0f0f0;
    height: auto;
    max-width: 350px;
    width: auto;
    float: right;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 3% 4%;
}

.Picture-frame{
    background-color: white;
    max-height: 267px;
    max-width: 350px;
    padding: 3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    align-items: center;
    display: flex;
    float: left;
    width: auto;
    height: auto;
    margin-bottom: 30px;
}

.Image{
    width: 100%;
    height: 100%;
    margin: auto;
}

.MyName {
    font-weight: bolder;
    font-family: 'helvetica';
}

.ShortDescr {
    float: left;
    text-align: left;
}

p {
    font-family: 'helvetica';
    font-size: large;
    text-align: justify;
}